<template>
    <div>
        <v-layout wrap>
            <v-flex xs12 class="mt-4 text-center">
                <v-layout wrap class="mb-1">
                    <v-flex xs4 class="text-left">
                        <img class="logo ml-12" :src="require('@/assets/images/logo.png')" height="80" />
                    </v-flex>
                    <v-flex xs4 class="d-flex align-center justify-center">
                        <h1>Milk Run</h1>
                    </v-flex>
                    <v-flex xs4 class="d-flex align-center justify-center">
                        <h4>Relatório {{ $moment().format("DD/MM/YYYY") }}</h4>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12>
                <v-alert v-if="!hasData || error" type="error" outlined>
                    <div class="text-h6">
                        {{ $t("Não foi localizado nenhuma informação.") }}
                    </div>
                    <div>{{ $t("Solicite ao administrador do sistema a verificação do seu cadastro.") }}</div>
                </v-alert>
            </v-flex>
        </v-layout>

        <div v-if="hasData">
            <v-divider />
            <div v-for="(i, index) in itens" :key="index">
                <v-layout wrap class="mb-4">
                    <v-flex xs12 class="d-flex flex-no-wrap justify-center mb-4">
                        <h1>{{ i.client }}</h1>
                    </v-flex>
                    <v-flex xs12 class="d-flex flex-no-wrap justify-center mb-4">
                        <h1>Número de Coletas: {{ i.collect }}</h1>
                    </v-flex>
                    <v-flex xs12 sm6 v-if="i.collect > 0">
                        <v-layout wrap class="d-flex flex-no-wrap justify-center">
                            <v-flex lg2 class="d-none d-sm-flex" />
                            <v-flex xs12 sm12 md10 lg8>
                                <Box title="Coletados" :item="((i.pudo + i.seller) / i.collect) * 100" />
                            </v-flex>
                            <v-flex lg2 class="d-none d-sm-flex" />
                            <v-flex xs6 sm6 md5 lg3>
                                <Box title="PUDO" :item="(i.pudo / i.collect) * 100" />
                            </v-flex>
                            <v-flex xs6 sm6 md5 lg3>
                                <Box title="Seller" :item="(i.seller / i.collect) * 100" />
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 v-if="i.collect > 0">
                        <v-layout wrap class="d-flex flex-no-wrap justify-center">
                            <v-flex xs12 sm12 md10 lg8>
                                <Box title="Consolidado" :item="(i.consolidated / i.collect) * 100" />
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </div>
        </div>
    </div>
</template>

<script>
import Box from "./Box";

export default {
    components: { Box },

    data() {
        return {
            timer: null,
            show: false,
            error: true,
            interval: {},
            itens: [],
        };
    },

    methods: {
        update() {
            this.$http
                .get(`partners/dashboard-milkrun`, { continuous: true })
                .then((result) => {
                    if (result) {
                        this.error = false;
                        this.itens = result;
                    } else {
                        this.error = true;
                    }
                })
                .catch(() => (this.error = true));
        },
    },

    mounted() {
        this.update();
        this.timer = setInterval(() => this.update(), 10000);
    },

    computed: {
        hasData() {
            return this.itens != null && this.itens.length > 0;
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
